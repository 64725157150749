<template>
  <div class="title-bar">
    <div class="user-info">
      <img class="user-avatar" :src="userAvatar" alt="User Avatar" />
      <span class="user-name">{{ userName }}</span>
    </div>
    <div class="profit-info">
      <img class="icon globe-icon" src="../assets/glob.png" alt="Globe" />
      <div class="profit-details">
        <span class="profit-text">Profit per hour</span>
        <div class="profit-values">
          <i class="fas fa-gem"></i>
          <span class="profit-amount">{{ profit }} 💡</span>
        </div>
      </div>
      <img class="icon settings-icon" src="../assets/settings.png" alt="Settings" @click="openSettings"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    userName: { type: String, default: "User228" },
    userAvatar: { type: String, default: "https://via.placeholder.com/50" },
    profit: { type: Number, default: 100 }
  },
  methods: {
    openSettings() {
      
      alert("Settings clicked!");
    }
  }
};
</script>

<style scoped>
.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #014e44;
  padding: 8px 16px;
  border-radius: 20px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid #004238;
}

.user-name {
  font-size: 1.2em;
  font-weight: bold;
}

.profit-info {
  display: flex;
  align-items: center;
  background-color: #005b46;
  padding: 5px 10px;
  border-radius: 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.profit-details {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.profit-text {
  font-size: 0.9em;
  color: #e0e0e0;
}

.profit-values {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
}

.icon {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.icon:hover {
  transform: scale(1.1);
}

.fas.fa-gem {
  color: #3ee2ff;
}
</style>
