<template>
  <div class="bottom-navigation">
    <router-link to="/" class="nav-item">
      <img src="../assets/factory.png" alt="Factories" />
      <span>Factories</span>
    </router-link>
    <router-link to="/mine" class="nav-item">
      <img src="../assets/pickaxe.png" alt="Mine" />
      <span>Mine</span>
    </router-link>
    <router-link to="/inventory" class="nav-item">
      <img src="../assets/inventory.png" alt="Inventory" />
      <span>Inventory</span>
    </router-link>
    <router-link to="/friends" class="nav-item">
      <img src="../assets/friends.png" alt="Friends" />
      <span>Friends</span>
    </router-link>
    <router-link to="/tasks" class="nav-item">
      <img src="../assets/diamond1.png" alt="Tasks" />
      <span>Tasks</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BottomNavBar",
};
</script>

<style scoped>
.bottom-navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #002324;
  padding: 10px;
  border-radius: 10px;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #00ffff;
  cursor: pointer;
  transition: transform 0.3s ease;
  text-decoration: none;
}

.nav-item img {
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
}

.nav-item span {
  font-size: 0.8em;
}

.nav-item:hover {
  transform: scale(1.1);
}

.nav-item:active {
  transform: scale(0.9);
}
</style>
