<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="content">
      <div class="game-container">
        <titlebar />
  
        <div class="hexagon-grid">
          <div class="row row-1">
            <FactoryTile v-for="n in 3" :key="n" />
          </div>
          <div class="row row-2">
            <FactoryTile v-for="n in 2" :key="n" />
          </div>
          <div class="row row-3">
            <FactoryTile v-for="n in 3" :key="n" />
          </div>
          <div class="row row-4">
            <FactoryTile v-for="n in 2" :key="n" />
          </div>
        </div>
  
        <BottomNavBar class="bottom-nav" />
      </div>
    </div>
  </template>
  
  <script>
  import titlebar from "../components/titlebar.vue";
  import FactoryTile from "../components/FactoryTile.vue";
  import BottomNavBar from "../components/BottomNavBar.vue";
  
  export default {
  components: {
    titlebar,
    FactoryTile,
    BottomNavBar,
  },
  data() {
    return {
      totalProfit: 0,
      profitPerSecond: 0,
      money: 100,
      cells: [
        { type: "factory", level: 1, profit: 1 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
        { type: "empty", profit: 0 },
      ],
      unlockedCells: [0],
    };
  },
  mounted() {
    this.startProduction();
    this.updateProfitPerSecond();
  },
  methods: {
    getRows() {
      const rows = [];
      let start = 0;
      let isOddRow = true;
  
      while (start < this.cells.length) {
        const rowSize = isOddRow ? 3 : 2;
        rows.push(this.cells.slice(start, start + rowSize));
        start += rowSize;
        isOddRow = !isOddRow;
      }
      return rows;
    },
    getClass(cell) {
      return {
        "factory-cell": cell.type === "factory",
        "buy-factory-cell": cell.type === "buy-factory",
        "empty-cell": cell.type === "empty",
        "adjusted": this.isAdjusted(cell),
        unlocked: this.unlockedCells.includes(cell),
      };
    },
    handleClick(cell, index) {
      if (cell.type === "empty" && this.money >= 50) {
        this.money -= 50;
        this.cells[index].type = "buy-factory";
        this.unlockNeighbors(index);
      } else if (cell.type === "buy-factory" && this.money >= 100) {
        this.money -= 100;
        this.cells[index].type = "factory";
        this.cells[index].level = 1;
        this.cells[index].profit = 1;
        this.updateProfitPerSecond();
      } else if (cell.type === "factory") {
        const upgradeCost = cell.level * 50;
        if (this.money >= upgradeCost) {
          this.money -= upgradeCost;
          cell.level += 1;
          cell.profit += this.getFibonacciNumber(cell.level);
          this.updateProfitPerSecond();
        }
      }
    },
    unlockNeighbors(index) {
      const neighbors = this.getNeighbors(index);
      neighbors.forEach((neighbor) => {
        if (!this.unlockedCells.includes(neighbor)) {
          this.unlockedCells.push(neighbor);
          if (!this.cells[neighbor]) {
            this.cells[neighbor] = { type: "empty", profit: 0 };
          }
        }
      });
    },
    getNeighbors(index) {
      const neighbors = [];
      const cols = 3;
      if (index - cols >= 0) neighbors.push(index - cols);
      if (index + cols < this.cells.length) neighbors.push(index + cols);
      if (index % cols !== 0) neighbors.push(index - 1);
      if ((index + 1) % cols !== 0) neighbors.push(index + 1);
      return neighbors;
    },
    getFibonacciNumber(n) {
      if (n <= 1) return n;
      let a = 0,
        b = 1,
        temp;
      for (let i = 2; i <= n; i++) {
        temp = a + b;
        a = b;
        b = temp;
      }
      return b;
    },
    updateProfitPerSecond() {
      this.profitPerSecond = this.cells.reduce((acc, cell) => {
        return cell.type === "factory" ? acc + cell.profit : acc;
      }, 0);
    },
    startProduction() {
      setInterval(() => {
        this.cells.forEach((cell) => {
          if (cell.type === "factory") {
            this.money += cell.profit;
            this.totalProfit += cell.profit;
          }
        });
      }, 1000);
    },
    openSettings() {
      alert("Settings clicked!");
    },
    isAdjusted(cell) {
      return cell.level > 3;
    },
  },
  };
  </script>
  
  <style scoped>
  html, body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  }
  
  * {
  box-sizing: border-box;
  }
  
  .content {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  }
  
  .game-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #001c1f;
  color: #00ffff;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  }
  
  .bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #002d2f;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  }
  
  .hexagon-grid {
  display: flex;
  flex-direction: column;
  gap: 30px;
  }
  
  .row {
  display: flex;
  justify-content: center;
  gap: 10px;
  }
  
  .row-1 {
  margin-bottom: -110px;
  }
  
  .row-2 {
  margin-bottom: -110px;
  }
  
  .row-3 {
  margin-bottom: 20px;
  }
  
  .row-4 {
  position: relative;
  top: -130px;
  }
  
  .icon-container {
  position: relative;
  margin: 0 -40px;
  }
  
  .hexagon-image {
  width: 100%;
  height: 100%;
  }
  
  .factory-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  }
  
  .unlocked {
  border: 2px solid #00ff00;
  }
  
  .adjusted {
  border: 2px dashed #ff00ff;
  box-shadow: 0 0 10px #ff00ff;
  }
  </style>
  