<template>
  <div class="icon-container">
    <img src="../assets/hexagon.png" alt="Hexagon" class="hexagon-image" />
    <img src="../assets/factory.png" alt="Factory" class="factory-image" />
    <button class="overlay-button" @click="goToNextPage">
      <!-- Кнопка остается прозрачной, поэтому содержимое не нужно -->
    </button>
  </div>
</template>

<script>
export default {
  name: 'FactoryIcon',
  props: {
    factoryImage: {
      type: String,
      required: true
    }
  },
  methods: {
    goToNextPage() {
      // Здесь можно передать данные о плитке, например, название или ID.
      const tileData = {
        imageUrl: this.factoryImage,
        hexagonUrl: '../assets/hexagon.png'
      };
      // Здесь вы можете использовать маршрутизацию Vue для перехода на следующую страницу.
      this.$router.push({
        name: 'NextPage',
        params: { tileData }
      });
    }
  }
};
</script>

<style scoped>
.icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  padding: 20px;
}

.hexagon-image {
  width: 100%;
  height: auto;
}

.factory-image {
  position: absolute;
  top: 50%;
  left: 52.5%;
  width: 70px;
  height: 70px;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.overlay-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.overlay-button:hover {
  background: rgba(0, 0, 0, 0.1); /* Добавляем легкую прозрачную заливку при наведении */
}
</style>
