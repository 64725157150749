import { createRouter, createWebHistory } from 'vue-router'
import App from '../App.vue';
import Index from '../pages/Index.vue';
import Mine from '../pages/Mine.vue';

const routes = [
  {
    path: '/',
    component: App,
    children: [
      {
        path: '',
        name: 'Index',
        component: Index
      }
    ]
  },
  {
    path: '/mine',
    name: 'mine',
    component: Mine
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;