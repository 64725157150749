<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="content">
      <div class="game-container">
        <titlebar />
  
        <!-- Счётчик монет и иконка руды перед тапалкой -->
        <div class="collected-coins">
          <img src="../assets/ore.png" class="coin-icon-big" />
          {{ coinCount }}
        </div>
        <div 
          class="hexagon-container" 
          @click="handleTap"
          :style="hexagonStyle"
        >
    
          <div class="hexagon" :class="{'shadow-effect': isTilted}">
            <img src="../assets/hexagon.png" class="hexagon-texture" />
            <div class="coin-count">
              <img src="../assets/ore.png" class="coin-icon" /> 
              {{ coinCount }}
            </div>
            <img src="../assets/oil-pump.png" class="oil-pump" />
          </div>
        </div>
    
  
        <!-- Boost and progress bar section -->
        <div class="boost-container">
          <div class="progress-bar">
            <img src="../assets/ore.png" class="progress-icon" />
            <div class="progress-text">{{ energy }}/5000</div>
          </div>
          <div class="boost" @click="boostEnergy">
            <img src="../assets/boost.png" class="boost-icon" />
            Boost
          </div>
        </div>
  
        <div class="floating-text-container">
          <div 
            v-for="(text, index) in floatingTexts" 
            :key="index" 
            class="floating-text" 
            :style="text.style">
            +1
          </div>
        </div>
  
        <BottomNavBar class="bottom-nav" />
      </div>
    </div>
  </template>
  
  <script>
  import titlebar from "../components/titlebar.vue";
  import BottomNavBar from "../components/BottomNavBar.vue";
  
  export default({
    components: {
      titlebar,
      BottomNavBar,
    },
    data() {
      return {
        coinCount: 0,
        energy: 5000,
        hexagonStyle: {
          transform: 'rotateX(0deg) rotateY(0deg)',
          transition: 'transform 0.2s ease'
        },
        isTilted: false,
        floatingTexts: [],
        energyInterval: null,
      };
    },
    mounted() {
      this.energyInterval = setInterval(() => {
        if (this.energy < 5000) {
          this.energy++;
        }
      }, 1000);
    },
    beforeUnmount() {
      clearInterval(this.energyInterval);
    },
    methods: {
      handleTap(event) {
        if (this.energy > 0) {
          this.coinCount++;
          this.energy--;
          this.createFloatingText(event);
          this.tiltHexagon(event);
        }
      },
      createFloatingText(event) {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
  
        const floatingText = {
          id: Date.now(),
          style: {
            position: 'absolute',
            left: `${x}px`,
            top: `${y}px`,
            opacity: 1,
            transition: 'transform 1s ease, opacity 1s ease',
          },
        };
  
        this.floatingTexts.push(floatingText);
  
        setTimeout(() => {
          floatingText.style.transform = 'translateY(-50px)';
          floatingText.style.opacity = 0;
        }, 10);
  
        setTimeout(() => {
          this.floatingTexts = this.floatingTexts.filter(text => text.id !== floatingText.id);
        }, 1000);
      },
      tiltHexagon(event) {
        const rect = event.currentTarget.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
  
        const deltaX = event.clientX - centerX;
        const deltaY = event.clientY - centerY;
  
        const maxAngle = 80;
        const angleX = (deltaY / rect.height) * maxAngle * -1;
        const angleY = (deltaX / rect.width) * maxAngle;
  
        this.hexagonStyle = {
          transform: `rotateX(${angleX}deg) rotateY(${angleY}deg)`,
          transition: 'transform 0.2s ease, box-shadow 0.2s ease'
        };
        this.isTilted = true;
  
        setTimeout(() => {
          this.hexagonStyle = {
            transform: 'rotateX(0deg) rotateY(0deg)',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease'
          };
          this.isTilted = false;
        }, 200);
      },
      boostEnergy() {
        this.energy += 500;
      }
    }
  });
  </script>
  
  <style scoped>
  html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .content {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .game-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #001c1f;
    color: #00ffff;
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .collected-coins {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    color: #00ffff;
    font-size: 24px;
    z-index: 10;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 10px;

  }
  
  .coin-icon-big {
    width: 40px;
    margin-right: 10px;
  }
  
  .hexagon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 150px);
    position: relative;
    margin-bottom: 30px;
  }
  


  .hexagon {
    width: 420px;
    height: 420px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
    z-index: 1;
    margin-bottom: 110px;
  }
  
  .hexagon-texture {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .oil-pump {
    width: 170px;
    z-index: 2;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .coin-count {
    position: absolute;
    top: -30px;
    color: #00ffff;
    font-size: 24px;
    z-index: 2;
    display: flex;
    align-items: center;
  }
  
  .coin-icon {
    width: 24px;
    margin-right: 5px;
  }
  
  .shadow-effect {
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5), -5px -5px 15px rgba(255, 255, 255, 0.3);
  }
  
  .boost-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 120px;
    background-color: #002d2f;
    border-radius: 10px;
    z-index: 5;
  }
  
  .progress-bar {
    display: flex;
    align-items: center;
  }
  
  .progress-icon {
    width: 24px;
    margin-right: 10px;
  }
  
  .progress-text {
    color: #00ffff;
    font-size: 16px;
  }
  
  .boost {
    display: flex;
    align-items: center;
    color: #00ffff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .boost-icon {
    width: 24px;
    margin-right: 5px;
  }
  
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #002d2f;
    padding: 10px;
    display: flex;
    justify-content: space-around;
  }
  
  .floating-text-container {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .floating-text {
    position: absolute;
    color: #00ffff;
    font-size: 18px;
  }
  </style>
  